import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
  
const NotFoundPage = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
        const handleKeyPress = (event) => {
            navigate('/');
          };
    
        const handleTouch = () => {
          navigate('/');
        };
  
    // Add keypress event listener for handling "Enter" key
    document.addEventListener('keydown', handleKeyPress);
    // Add click and touch event listeners for handling screen touches and mouse clicks
    document.addEventListener('click', handleTouch);
    document.addEventListener('touchstart', handleTouch);

    return () => {
      // Cleanup the event listeners when the component unmounts
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('click', handleTouch);
      document.removeEventListener('touchstart', handleTouch);
    };
  }, [navigate]); // Including navigate in the dependencies array ensures that it has the latest value

  
    return (
      <div className="kernel-panic">
        <p>Kernel Panic - not syncing: VFS: Unable to mount root fs on unknown-block(404,404)</p>
        <p>Press any key to reboot.</p>
      </div>
    );
  };

  
const HomePage = () => {
    const [output, setOutput] = useState(['']);
    const [command, setCommand] = useState('');
    const [commandHistory, setCommandHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const inputRef = useRef(null);
    const outputRef = useRef(null);

    const sections = {
        'about': `\n]3 |_ () ( /< |\\/| () \\/ [-\n\nWe are a staking service.\n\nEst. 2019. Proudly built in Czechia.\n\nMaximize your crypto returns.\n\nStake with us securely and efficiently.\n\nContact us at hello@blockmove.eu\n\n`,
        'sauce': `\n_\\~ /\\ |_| ( [-\n\nBuilt for decentralization and redundancy.\n\nAlways available, resilient, and secure.\n\n24/7 optimal performance and reliability.\n\n`,
        'social': 'Find us on X at @eublockmove\nFind us on Telegram at @blockmove\nFind us on Farcaster at @blockmove\n\nOther accounts claiming to be us are fake.',
        'blockmove': `\n]3 |_ () ( /< |\\/| () \\/ [-\n\nYou found us, well done!\n\n`,
        'pwd': `/var/www/html`,
        'random': () => {
            const randomNumbers1to50 = Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 1);
            const randomNumbers1to12 = Array.from({ length: 2 }, () => Math.floor(Math.random() * 12) + 1);
            const firstFive = randomNumbers1to50.map(num => `<span style="color: yellow;">${num}</span>`);
            const lastTwo = randomNumbers1to12.map(num => `<span style="color: white;">${num}</span>`);
            return `${firstFive.join(' ')} ${lastTwo.join(' ')}`;
        },
        'contact': 'Contact us at hello@blockmove.eu'
    };

    useEffect(() => {
        setOutput(prevOutput => [
            ...prevOutput,
            '',
            `]3 |_ () ( /< |\\/| () \\/ [-\n\nEnter "help" for a list of commands.`
        ]);
        inputRef.current.focus();

        // Ensure that the input field is focused whenever the user clicks anywhere on the screen
        const handleFocus = (e) => {
            if (e.target.tagName !== 'INPUT') {
                inputRef.current.focus();
                setCursorToEnd(inputRef.current);
            }
        };

        window.addEventListener('click', handleFocus);
        return () => {
            window.removeEventListener('click', handleFocus);
        };
    }, []);

    const setCursorToEnd = (input) => {
        const length = input.value.length;
        input.setSelectionRange(length, length);
    };

    const handleInput = (e) => {
        if (e.key === 'Enter') {
            const trimmedCommand = command.trim();
            setOutput(prevOutput => [...prevOutput, '> ' + trimmedCommand]);
            processCommand(trimmedCommand);
            setCommand('');
            setTimeout(() => {
                inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 100); // Delay the scroll slightly to allow the DOM to update
        } else {
            handleArrowKeys(e);
        }
    };

    const processCommand = (command) => {
        if (command === '') {
            setOutput(prevOutput => [...prevOutput, '']);
        } else if (command.toLowerCase() === 'clear') {
            setOutput(['\n\n', ``]);
        } else if (command.toLowerCase() === 'exit') {
            setOutput(prevOutput => [...prevOutput, 'Please close the tab manually.']);
        } else if (command.toLowerCase() in sections) {
            const result = typeof sections[command.toLowerCase()] === 'function'
                ? sections[command.toLowerCase()]()
                : sections[command.toLowerCase()];
            setOutput(prevOutput => [...prevOutput, <span dangerouslySetInnerHTML={{ __html: result }} />]);
        } else if (command.toLowerCase() === 'help') {
            setOutput(prevOutput => [...prevOutput, '\ncommands:\n\n- about\n- sauce\n- random\n- social\n- contact\n\n']);
        } else {
            setOutput(prevOutput => [...prevOutput, 'Command not found. Enter "help" for a list of commands.']);
        }
        if (command) {
            setCommandHistory(prevHistory => [...prevHistory, command]);
            setHistoryIndex(-1);
        }
    };

    const handleArrowKeys = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            const increment = e.key === 'ArrowUp' ? -1 : 1;
            const newIndex = historyIndex + increment;
            const adjustedIndex = newIndex < 0 ? commandHistory.length - 1 : newIndex % commandHistory.length;
            setCommand(commandHistory[adjustedIndex]);
            setHistoryIndex(adjustedIndex);
            setTimeout(() => setCursorToEnd(inputRef.current), 0);
        }
    };

    return (
        <div className="terminal">
            <div className="output" ref={outputRef}>
                {output.map((line, index) => <div key={index}>{line}</div>)}
                <div className="input-line">
                    <span>&gt; </span>
                    <input
                        ref={inputRef}
                        type="text"
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                        onKeyDown={handleInput}
                        autoFocus
                    />
                </div>
            </div>
        </div>
    );
};

const styles = `
body {
    background-color: #000;
    color: #0f0;
    font-family: monospace;
    margin: 0;
    padding: 20px;
    overflow: hidden;
}

.terminal {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
}

.output {
    flex: 1;
    overflow-y: auto;
    white-space: pre-wrap;
    padding-bottom: 20px; /* Added to prevent shifting */
}

.input-line {
    display: flex;
    padding-bottom: 10px; /* Added to prevent shifting */
}

input {
    background: none;
    border: none;
    color: #0f0;
    font-family: monospace;
    font-size: 1em;
    outline: none;
    flex: 1;
    padding: 0;
    margin: 0;
}

@media (max-width: 600px) {
    body {
        padding: 10px;
    }

    .output {
        font-size: 1em;
    }

    .input-line {
        flex-direction: row;
    }

    input {
        font-size: 1em;
    }
}

.kernel-panic {
    background-color: black;
    color: red;
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
    text-align: left;
    white-space: pre-wrap; /* Maintains whitespace formatting */
    border: 2px solid red;
  }
  
  .kernel-panic p {
    margin: 5px 0;
  }
  
  .kernel-panic span {
    cursor: pointer;
    color: lime;
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

// Add the viewport meta tag
const viewportMetaTag = document.createElement('meta');
viewportMetaTag.name = 'viewport';
viewportMetaTag.content = 'width=device-width, initial-scale=1.0, user-scalable=no';
document.head.appendChild(viewportMetaTag);

// Add the favicon
const link = document.createElement('link');
link.rel = 'icon';
link.type = 'image/svg+xml';
link.href = 'data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22><rect width=%2216%22 height=%2216%22 fill=%22%23000%22/><text x=%224%22 y=%2212%22 fill=%22%230f0%22 font-family=%22monospace%22 font-size=%2212%22>b</text></svg>';
document.head.appendChild(link);

document.title = 'blockmove';



const App = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    );
  };

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
